<template>
    <section class="environment-slider">
        <div class="header-title luxury">{{ header }}</div>
        <div class="swiper-container project-full-slider">
            <div class="swiper-wrapper">
                <div v-for="(item,index) in slides" :key="index" class="swiper-slide">
                    <div class="swiper-slide-item-content">
                        <!--                        <div class="header-title luxury">{{ header }}</div>-->
<!--                        <picture>-->
<!--                            <source-->
<!--                                media="(max-width:767px)"-->
<!--                                :srcset="item.mobile"-->
<!--                            />-->
<!--                            <source-->
<!--                                media="(max-width:1023px)"-->
<!--                                :srcset="item.tablet"-->
<!--                            />-->
<!--                            <img class="slide-image"-->
<!--                                 :src="item.desktop"-->
<!--                            />-->
<!--                        </picture>-->
                        <ImageScale :item="{image:item.desktop}"></ImageScale>
                        <div class="about">
                            <h2 class="title fira uppercase">{{ item.title }}</h2>
                            <span v-html="item.teaser" class="description"></span>
                        </div>
                    </div>
                    <div class="get-position-slide"></div>
                </div>
            </div>
            <div class="swiper-button-inner">
                <div class="item"></div>
                <div class="item">
                    <div @click="prevSwipe" class="swiper-button-prev swiper-button">
                        <svg width="79" height="12" viewBox="0 0 79 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M77.876 6.29298L1.52813 6.29298" stroke="#F40146"
                                  stroke-linecap="round"/>
                            <mask id="path-2-inside-1_1440_25437" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M4.8037 1.01173L0.238431 5.76484C-0.0417161 6.05652 -0.0417161 6.52942 0.238431 6.82109L4.8037 11.5742C5.08385 11.8659 5.53806 11.8659 5.81821 11.5742C6.09836 11.2825 6.09836 10.8096 5.81821 10.518L1.76019 6.29297L5.81821 2.06797C6.09836 1.7763 6.09836 1.3034 5.81821 1.01173C5.53806 0.72005 5.08385 0.72005 4.8037 1.01173Z"/>
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M4.8037 1.01173L0.238431 5.76484C-0.0417161 6.05652 -0.0417161 6.52942 0.238431 6.82109L4.8037 11.5742C5.08385 11.8659 5.53806 11.8659 5.81821 11.5742C6.09836 11.2825 6.09836 10.8096 5.81821 10.518L1.76019 6.29297L5.81821 2.06797C6.09836 1.7763 6.09836 1.3034 5.81821 1.01173C5.53806 0.72005 5.08385 0.72005 4.8037 1.01173Z"
                                  fill="#940128"/>
                            <path
                                d="M0.238431 5.76484L-1.204 4.37942L-1.204 4.37942L0.238431 5.76484ZM0.238431 6.82109L-1.204 8.20652H-1.204L0.238431 6.82109ZM4.8037 11.5742L3.36127 12.9596L4.8037 11.5742ZM5.81821 10.518L7.26064 9.13254H7.26064L5.81821 10.518ZM1.76019 6.29297L0.31776 7.67839L-1.01291 6.29297L0.31776 4.90755L1.76019 6.29297ZM-1.204 4.37942L3.36127 -0.373698L6.24613 2.39715L1.68086 7.15027L-1.204 4.37942ZM-1.204 8.20652C-2.22757 7.14082 -2.22757 5.44511 -1.204 4.37942L1.68086 7.15027C2.14414 6.66793 2.14414 5.91801 1.68086 5.43567L-1.204 8.20652ZM3.36127 12.9596L-1.204 8.20652L1.68086 5.43567L6.24613 10.1888L3.36127 12.9596ZM7.26064 12.9596C6.19344 14.0707 4.42847 14.0707 3.36127 12.9596L6.24613 10.1888C5.73923 9.66103 4.88268 9.66103 4.37578 10.1888L7.26064 12.9596ZM7.26064 9.13254C8.28421 10.1982 8.28421 11.8939 7.26064 12.9596L4.37578 10.1888C3.9125 10.6711 3.9125 11.421 4.37578 11.9034L7.26064 9.13254ZM3.20262 4.90755L7.26064 9.13254L4.37578 11.9034L0.31776 7.67839L3.20262 4.90755ZM7.26064 3.4534L3.20262 7.67839L0.31776 4.90755L4.37578 0.68255L7.26064 3.4534ZM7.26064 -0.373698C8.28421 0.691994 8.28421 2.3877 7.26064 3.4534L4.37578 0.68255C3.9125 1.16489 3.9125 1.91481 4.37578 2.39715L7.26064 -0.373698ZM3.36127 -0.373698C4.42847 -1.48481 6.19344 -1.48481 7.26064 -0.373698L4.37578 2.39715C4.88268 2.92491 5.73923 2.92491 6.24613 2.39715L3.36127 -0.373698Z"
                                fill="#F40146" mask="url(#path-2-inside-1_1440_25437)"/>
                        </svg>
                    </div>
                </div>
                <div class="item"></div>
                <div class="item">
                    <div @click="nextSwipe" class="swiper-button-next swiper-button">
                        <svg width="79" height="12" viewBox="0 0 79 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M77.876 6.29298L1.52813 6.29298" stroke="#F40146"
                                  stroke-linecap="round"/>
                            <mask id="path-2-inside-1_1440_25437" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M4.8037 1.01173L0.238431 5.76484C-0.0417161 6.05652 -0.0417161 6.52942 0.238431 6.82109L4.8037 11.5742C5.08385 11.8659 5.53806 11.8659 5.81821 11.5742C6.09836 11.2825 6.09836 10.8096 5.81821 10.518L1.76019 6.29297L5.81821 2.06797C6.09836 1.7763 6.09836 1.3034 5.81821 1.01173C5.53806 0.72005 5.08385 0.72005 4.8037 1.01173Z"/>
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M4.8037 1.01173L0.238431 5.76484C-0.0417161 6.05652 -0.0417161 6.52942 0.238431 6.82109L4.8037 11.5742C5.08385 11.8659 5.53806 11.8659 5.81821 11.5742C6.09836 11.2825 6.09836 10.8096 5.81821 10.518L1.76019 6.29297L5.81821 2.06797C6.09836 1.7763 6.09836 1.3034 5.81821 1.01173C5.53806 0.72005 5.08385 0.72005 4.8037 1.01173Z"
                                  fill="#940128"/>
                            <path
                                d="M0.238431 5.76484L-1.204 4.37942L-1.204 4.37942L0.238431 5.76484ZM0.238431 6.82109L-1.204 8.20652H-1.204L0.238431 6.82109ZM4.8037 11.5742L3.36127 12.9596L4.8037 11.5742ZM5.81821 10.518L7.26064 9.13254H7.26064L5.81821 10.518ZM1.76019 6.29297L0.31776 7.67839L-1.01291 6.29297L0.31776 4.90755L1.76019 6.29297ZM-1.204 4.37942L3.36127 -0.373698L6.24613 2.39715L1.68086 7.15027L-1.204 4.37942ZM-1.204 8.20652C-2.22757 7.14082 -2.22757 5.44511 -1.204 4.37942L1.68086 7.15027C2.14414 6.66793 2.14414 5.91801 1.68086 5.43567L-1.204 8.20652ZM3.36127 12.9596L-1.204 8.20652L1.68086 5.43567L6.24613 10.1888L3.36127 12.9596ZM7.26064 12.9596C6.19344 14.0707 4.42847 14.0707 3.36127 12.9596L6.24613 10.1888C5.73923 9.66103 4.88268 9.66103 4.37578 10.1888L7.26064 12.9596ZM7.26064 9.13254C8.28421 10.1982 8.28421 11.8939 7.26064 12.9596L4.37578 10.1888C3.9125 10.6711 3.9125 11.421 4.37578 11.9034L7.26064 9.13254ZM3.20262 4.90755L7.26064 9.13254L4.37578 11.9034L0.31776 7.67839L3.20262 4.90755ZM7.26064 3.4534L3.20262 7.67839L0.31776 4.90755L4.37578 0.68255L7.26064 3.4534ZM7.26064 -0.373698C8.28421 0.691994 8.28421 2.3877 7.26064 3.4534L4.37578 0.68255C3.9125 1.16489 3.9125 1.91481 4.37578 2.39715L7.26064 -0.373698ZM3.36127 -0.373698C4.42847 -1.48481 6.19344 -1.48481 7.26064 -0.373698L4.37578 2.39715C4.88268 2.92491 5.73923 2.92491 6.24613 2.39715L3.36127 -0.373698Z"
                                fill="#F40146" mask="url(#path-2-inside-1_1440_25437)"/>
                        </svg>
                    </div>
                </div>
                <div class="item"></div>
            </div>
            <div class="mobile-arrows">
                <div @click="prevSwipe" class="swiper-button-next swiper-button">
                    <svg width="79" height="12" viewBox="0 0 79 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.875977 6.29296L77.2238 6.29298" stroke="#F40146" stroke-linecap="round"/>
                        <mask id="path-2-inside-1_1440_25919" fill="white">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M73.9482 11.5742L78.5135 6.82111C78.7937 6.52943 78.7937 6.05654 78.5135 5.76486L73.9483 1.01174C73.6681 0.720065 73.2139 0.720065 72.9337 1.01174C72.6536 1.30342 72.6536 1.77631 72.9337 2.06799L76.9918 6.29298L72.9337 10.518C72.6536 10.8097 72.6536 11.2826 72.9337 11.5742C73.2139 11.8659 73.6681 11.8659 73.9482 11.5742Z"/>
                        </mask>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M73.9482 11.5742L78.5135 6.82111C78.7937 6.52943 78.7937 6.05654 78.5135 5.76486L73.9483 1.01174C73.6681 0.720065 73.2139 0.720065 72.9337 1.01174C72.6536 1.30342 72.6536 1.77631 72.9337 2.06799L76.9918 6.29298L72.9337 10.518C72.6536 10.8097 72.6536 11.2826 72.9337 11.5742C73.2139 11.8659 73.6681 11.8659 73.9482 11.5742Z"
                              fill="#940128"/>
                        <path
                            d="M78.5135 6.82111L79.956 8.20653L79.956 8.20653L78.5135 6.82111ZM78.5135 5.76486L79.956 4.37944L79.956 4.37944L78.5135 5.76486ZM73.9483 1.01174L75.3907 -0.373683L73.9483 1.01174ZM72.9337 2.06799L71.4913 3.45341L71.4913 3.45341L72.9337 2.06799ZM76.9918 6.29298L78.4342 4.90756L79.7649 6.29299L78.4342 7.67841L76.9918 6.29298ZM79.956 8.20653L75.3907 12.9597L72.5058 10.1888L77.0711 5.43569L79.956 8.20653ZM79.956 4.37944C80.9795 5.44513 80.9795 7.14084 79.956 8.20653L77.0711 5.43569C76.6078 5.91803 76.6078 6.66794 77.0711 7.15028L79.956 4.37944ZM75.3907 -0.373683L79.956 4.37944L77.0711 7.15028L72.5058 2.39716L75.3907 -0.373683ZM71.4913 -0.373683C72.5585 -1.48479 74.3235 -1.48479 75.3907 -0.373683L72.5058 2.39716C73.0127 2.92492 73.8693 2.92492 74.3762 2.39716L71.4913 -0.373683ZM71.4913 3.45341C70.4677 2.38772 70.4677 0.692009 71.4913 -0.373683L74.3762 2.39716C74.8395 1.91482 74.8395 1.16491 74.3762 0.682566L71.4913 3.45341ZM75.5493 7.67841L71.4913 3.45341L74.3762 0.682566L78.4342 4.90756L75.5493 7.67841ZM71.4913 9.13256L75.5493 4.90756L78.4342 7.67841L74.3762 11.9034L71.4913 9.13256ZM71.4913 12.9597C70.4677 11.894 70.4677 10.1982 71.4913 9.13256L74.3762 11.9034C74.8395 11.4211 74.8395 10.6711 74.3762 10.1888L71.4913 12.9597ZM75.3907 12.9597C74.3235 14.0708 72.5585 14.0708 71.4913 12.9597L74.3762 10.1888C73.8693 9.66104 73.0127 9.66104 72.5058 10.1888L75.3907 12.9597Z"
                            fill="#F40146" mask="url(#path-2-inside-1_1440_25919)"/>
                    </svg>
                </div>
                <div @click="nextSwipe"  class="swiper-button-prev swiper-button">
                    <svg width="79" height="12" viewBox="0 0 79 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.875977 6.29296L77.2238 6.29298" stroke="#F40146" stroke-linecap="round"/>
                        <mask id="path-2-inside-1_1440_25919" fill="white">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M73.9482 11.5742L78.5135 6.82111C78.7937 6.52943 78.7937 6.05654 78.5135 5.76486L73.9483 1.01174C73.6681 0.720065 73.2139 0.720065 72.9337 1.01174C72.6536 1.30342 72.6536 1.77631 72.9337 2.06799L76.9918 6.29298L72.9337 10.518C72.6536 10.8097 72.6536 11.2826 72.9337 11.5742C73.2139 11.8659 73.6681 11.8659 73.9482 11.5742Z"/>
                        </mask>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M73.9482 11.5742L78.5135 6.82111C78.7937 6.52943 78.7937 6.05654 78.5135 5.76486L73.9483 1.01174C73.6681 0.720065 73.2139 0.720065 72.9337 1.01174C72.6536 1.30342 72.6536 1.77631 72.9337 2.06799L76.9918 6.29298L72.9337 10.518C72.6536 10.8097 72.6536 11.2826 72.9337 11.5742C73.2139 11.8659 73.6681 11.8659 73.9482 11.5742Z"
                              fill="#940128"/>
                        <path
                            d="M78.5135 6.82111L79.956 8.20653L79.956 8.20653L78.5135 6.82111ZM78.5135 5.76486L79.956 4.37944L79.956 4.37944L78.5135 5.76486ZM73.9483 1.01174L75.3907 -0.373683L73.9483 1.01174ZM72.9337 2.06799L71.4913 3.45341L71.4913 3.45341L72.9337 2.06799ZM76.9918 6.29298L78.4342 4.90756L79.7649 6.29299L78.4342 7.67841L76.9918 6.29298ZM79.956 8.20653L75.3907 12.9597L72.5058 10.1888L77.0711 5.43569L79.956 8.20653ZM79.956 4.37944C80.9795 5.44513 80.9795 7.14084 79.956 8.20653L77.0711 5.43569C76.6078 5.91803 76.6078 6.66794 77.0711 7.15028L79.956 4.37944ZM75.3907 -0.373683L79.956 4.37944L77.0711 7.15028L72.5058 2.39716L75.3907 -0.373683ZM71.4913 -0.373683C72.5585 -1.48479 74.3235 -1.48479 75.3907 -0.373683L72.5058 2.39716C73.0127 2.92492 73.8693 2.92492 74.3762 2.39716L71.4913 -0.373683ZM71.4913 3.45341C70.4677 2.38772 70.4677 0.692009 71.4913 -0.373683L74.3762 2.39716C74.8395 1.91482 74.8395 1.16491 74.3762 0.682566L71.4913 3.45341ZM75.5493 7.67841L71.4913 3.45341L74.3762 0.682566L78.4342 4.90756L75.5493 7.67841ZM71.4913 9.13256L75.5493 4.90756L78.4342 7.67841L74.3762 11.9034L71.4913 9.13256ZM71.4913 12.9597C70.4677 11.894 70.4677 10.1982 71.4913 9.13256L74.3762 11.9034C74.8395 11.4211 74.8395 10.6711 74.3762 10.1888L71.4913 12.9597ZM75.3907 12.9597C74.3235 14.0708 72.5585 14.0708 71.4913 12.9597L74.3762 10.1888C73.8693 9.66104 73.0127 9.66104 72.5058 10.1888L75.3907 12.9597Z"
                            fill="#F40146" mask="url(#path-2-inside-1_1440_25919)"/>
                    </svg>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
//slider
import Swiper, {FreeMode, Thumbs, Controller, Autoplay} from 'swiper';
import {computed, nextTick, onBeforeUnmount, onMounted} from "vue";
import gsap from "gsap";
import ImageScale from "../../Popups/ImageScale.vue";

Swiper.use([FreeMode, Thumbs, Controller, Autoplay]);

let props = defineProps({
    contentData: {
        type: Object,
    }
})
let swiper = null

const setSwiper = () => {
    swiper = new Swiper('.project-full-slider', {
        loop: true,
        touchRatio: 2.5,
        // freeMode: {
        //     enabled: true,
        //     sticky: false,
        // },
        grabCursor: true,
        centeredSlides: true,
        spaceBetween: 0,
        speed: 1200,
        slidesPerView: 1,
        breakpoints: {
            767: {
                spaceBetween: 175,
                speed: 1200,
                slidesPerView: 1.5,
            }
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    swiper.on('touchStart', () => {
        // gsap.to(".project-full-slider .swiper-slide", {
        //     scale: 0.7,
        //     duration: .6,
        // });
        gsap.to(".about", {
            opacity: 0,
            duration: .6,
        });
        gsap.to(".swiper-button-inner", {
            opacity: 0,
            duration: .6,
        });
    });
    swiper.on('touchEnd', () => {
        // gsap.to(".project-full-slider .swiper-slide", {
        //     scale: 1,
        //     duration: .6,
        // });
        gsap.to(".about", {
            opacity: 1,
            duration: .6,
            delay: .5,
        });
        gsap.to(".swiper-button-inner", {
            opacity: 1,
            duration: .6,
            delay: .5,
        });
    });
}
const nextSwipe = () => {
    swiper.slideNext()
}
const prevSwipe = () => {
    swiper.slidePrev()
}
const setSizes = async () => {
    await nextTick()
    let items = document.querySelectorAll('.swiper-button-inner .item')
    let smallItemSize = Math.abs(document.querySelector('.swiper-slide-prev .get-position-slide')?.getBoundingClientRect()?.left)
    let outerSize = 2 * smallItemSize + 350

    items[0].style.width = smallItemSize + 'px'
    items[4].style.width = smallItemSize + 'px'
    items[2].style.width = `calc(100% - ${outerSize}px)`
}
let slides = computed(() => props.contentData.data?.list?.map(item => ({
    ...item,
    mobile: item?.images?.[0]?.devices?.mobile,
    desktop: item?.images?.[0]?.devices?.desktop,
    tablet: item?.images?.[0]?.devices?.tablet,
})))
let header = computed(() => props.contentData.conf?.header?.title)
const resizeEventHandler = () => {
    window.addEventListener('resize', setSizes)
}

onMounted(() => {
    setSwiper()
    setSizes()
    resizeEventHandler()
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', setSizes)
})

</script>

<style lang="scss" scoped>
.environment-slider {
    position: relative;
    $ease: cubic-bezier(.43, 0, .29, .99);
    $ease-in: cubic-bezier(0, .19, .01, .99);
    padding-top: 180px;
    @media (max-width: 1023px) {
        padding-top: 100px;
    }
    color: $primaryNavy;

    .header-title {
        position: absolute;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        font-style: normal;
        font-weight: 400;
        font-size: 89px;
        text-transform: uppercase;
        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
            font-size: 59px;
        }
        @media (max-width: 1023px) {
            font-size: 34px;
            position: relative;
            text-align: center;
        }
    }

    .swiper-slide {
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        text-transform: uppercase;
        color: $primaryNavy;
        text-align: center;
        width: fit-content;


        &.swiper-slide-active {
            .slide-image {
                opacity: 1 !important;
            }

        }

        .get-position-slide {
            position: absolute;
            pointer-events: none;
            right: 0;
        }

        .swiper-slide-item-content {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;

            .slide-image {
                height: 610px;
                width: 100%;
                object-fit: cover;
                opacity: .3;
                transition: opacity .45s $ease;
                @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                    height: 510px;
                }
                @media only screen and (max-width: 1023px) {
                    height: 410px;
                }
                @media (max-width: 767px) {
                    width: 90%;
                    height: 168.43px;
                }
            }


            .about {
                margin-top: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @media (max-width: 1023px) {
                    margin-top: 21px;
                }

                .title {
                    font-weight: 700;
                    font-size: 34px;
                    text-align: center;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                        font-size: 28px;
                    }
                    @media (max-width: 1023px) {
                        font-size: 21px;
                    }
                }

                .description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 21px;
                    line-height: 25px;
                    text-align: center;
                    max-width: 60%;
                    display: block;
                    margin-top: 13px;
                    @media (max-width: 1023px) {
                        display: none;
                    }
                }
            }
        }

    }

    .swiper-container {
        position: relative;
    }

    .swiper-button-inner {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        @media only screen and (max-width: 1023px) {
            display: none;
        }

        .item {
            height: 100%;

            &:nth-child(2), &:nth-child(4) {
                width: 175px;
                height: 100%;
                display: flex;
                justify-content: center;

                svg {
                    margin-top: 305px;

                    @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                        margin-top: 260px;
                    }
                    @media only screen and (max-width: 1023px) {
                        margin-top: 205px;
                    }
                }

            }


            &:nth-child(4) {
                svg {
                    transform: rotate(180deg);
                }
            }

        }
    }

    .mobile-arrows {
        align-items: center;
        display: none;
        margin-top: 26px;
        @media (max-width: 1023px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .swiper-button-prev, .swiper-button-next {
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(0deg);
        }

        .swiper-button-next {
            transform: rotate(180deg);
            margin-right: 13px;
        }

        .swiper-button-prev {
            margin-top: -1px;
        }
    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .environment-slider {
            color: white !important;

            .swiper-slide {
                color: white !important;
            }
        }
    }
}

</style>
